// ** React Imports
import { Navigate } from "react-router-dom"
import { useContext, Suspense } from "react"

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can"
import { useQuery } from "@tanstack/react-query"
import AdminAPI from "@src/services/API"
import { SpinnerLoader } from '@Intelli/utilities'
import { useApplicationModules } from "@src/utility/hooks/useApplicationModules"

const PrivateRoute = ({ children, route }) => {
  
  const API = new AdminAPI()

  // ** Hooks & Vars
  const ability = useContext(AbilityContext)
  const { data: user, isError: userHasError, isLoading: userIsLoading } = useQuery(['user'], () => API.getUser())
  const { modules } = useApplicationModules()

  if (!userHasError && !userIsLoading) {
    const availableRoutes = ['wallet/home']
    for (const module of modules) {
      for (const metadata of module.metadata) {
        if (metadata?.metadata === 'navLink') {
          availableRoutes.push(metadata.value)
        }
      }
    }

    if (route) {
      let action = null
      let resource = null
      let restrictedRoute = false

      if (route.meta) {
        action = route.meta.action
        resource = route.meta.resource
        restrictedRoute = route.meta.restricted
      }
      
      const userHasAccess = availableRoutes.some(available_route => {
        return route.path.includes(available_route)
      })
      
      if (user && restrictedRoute && !userHasAccess) {
        return <Navigate to='/404' />
      }

      if (!user) {
        return <Navigate to="/" />
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
