// ** React Imports
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import { Power, ArrowLeft } from 'react-feather'

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from 'reactstrap'
import {
  SpinnerLoader,
  user$,
  getUserOperatingSystem
} from '@Intelli/utilities'
import { useMutation } from '@tanstack/react-query'
import WalletAPI from '../../services/API'
import { parseName } from '../../utility/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faUserTie,
  faDownload
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const CustomUserDropdown = () => {
  const [modalDownload, setModalDownload] = useState(false)
  const API = new WalletAPI()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = localStorage.getItem('token') || searchParams.get('token')

  const user = useSelector(state => state.userInfo)

  const { mutate: logout } = useMutation(() => API.logout(), {
    onSuccess: () => {
      localStorage.clear()
      navigate('/')
    }
  })

  const logoutHandler = e => {
    e.preventDefault()
    logout()
  }

  const Dropdown = () => {
    if (!user) {
      return <SpinnerLoader />
    }

    const { mutate: handleAdminWorkspaceChange } = useMutation(
      workspaceId => API.changeAdminWorkspace(workspaceId),
      {
        onSuccess: data => {
          localStorage.setItem('token', JSON.stringify(data.token.token))
          window.location = '/admin'
        }
      }
    )
    const os = getUserOperatingSystem()

    const { mutate: downloadApp, isLoading: appsDataArrayIsLoading } =
      useMutation(data => API.getDashboardApps(data), {
        onSuccess: d => {
          const appToDownload = d?.find(i => i.name === os.toUpperCase())    
          window.location.href = appToDownload?.url
        }
      })

    if (appsDataArrayIsLoading) return <SpinnerLoader />

    return (
      <>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            onClick={e => e.preventDefault()}
          >
            <div className="user-nav d-sm-flex d-none">
              <span className="fw-bold">
                {parseName(user?.user.first_name, user?.user.last_name)}
              </span>
            </div>
            {user.photo?.thumbnail ? (
              <Avatar
                img={user?.photo?.photo}
                imgHeight="40"
                imgWidth="40"
                imgClassName="avatar-cover"
              />
            ) : (
              <Avatar
                content={parseName(user?.user.first_name, user?.user.last_name)}
                initials
                color="info"
                imgHeight="40"
                imgWidth="40"
              />
            )}
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem tag={Link} to={`/wallet/account-settings`}>
              <FontAwesomeIcon icon="user" className="me-75" />
              <span className="align-middle">{t('profile')}</span>
            </DropdownItem>
            {/* <DropdownItem divider /> */}
            {/* <DropdownItem tag={Link} to="/wallet">
            <MessageCircle size={14} className="me-75" />
            Support
          </DropdownItem> */}
            {user$.value.companiesCount > 1 && (
              <>
                <DropdownItem tag={Link} to="/lobby?auto=false">
                  <ArrowLeft size={14} className="me-75" />
                  {t('companies')}
                </DropdownItem>
              </>
            )}
            {(user$.value.backoffice || user$?.value?.company?.admin) && (
              <>
                <DropdownItem divider />
                {/* <DropdownItem text header>
              <span className="align-middle">{t('change_to')}</span>
            </DropdownItem> */}
              </>
            )}
            {user$.value?.company?.admin && (
              <DropdownItem
                tag={Button}
                className="rounded-0"
                onClick={() =>
                  handleAdminWorkspaceChange({
                    access_id: user$.value.company.admin.access_id
                  })
                }
                color="link"
                block
              >
                <FontAwesomeIcon icon={faBuilding} className="me-75" />
                {t('admin')}
              </DropdownItem>
            )}
            {user$.value.backoffice && (
              <DropdownItem tag={Link} to="/backoffice" reloadDocument>
                <FontAwesomeIcon icon={faUserTie} className="me-75" />
                {t('backoffice')}
              </DropdownItem>
            )}
            <DropdownItem divider />

            {/* <DropdownItem
              tag={Link}
              to="/"
              onClick={e => {
                e.preventDefault()
                window.open(
                  'https://joobpay.odoo.com/knowledge/article/55',
                  '_blank'
                )
              }}
            >
              <FontAwesomeIcon
                icon="folder"
                size="lg"
                id="support"
                className="me-75"
              />
              <span className="align-middle">{t('FAQ')}</span>
            </DropdownItem> */}
            <DropdownItem
              tag={Link}
              to="/"
              onClick={e => {
                e.preventDefault()
                window.open('https://joobpay.com/support/', '_blank')
              }}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-user-headset"
                size="lg"
                id="support"
                className="me-75"
              />
              <span className="align-middle">{t('support')}</span>
            </DropdownItem>
            <DropdownItem
              onClick={()=> downloadApp()}
            >
              <FontAwesomeIcon className="me-75" icon={faDownload} />
              <span className="align-middle">{t('downloadApp')}</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem tag={Link} to="/" onClick={logoutHandler}>
              <Power size={14} className="me-75" />
              <span className="align-middle">{t('logOut')}</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    )
  }
  if (token) {
    return <Dropdown />
  } else {
    return <Navigate to="/" />
  }
}

export default CustomUserDropdown
