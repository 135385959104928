import React from 'react'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import {
  JpVerticalBarsCard,
  parseMinutesToHours,
  JpPercentageText,
  JpGraphCard,
  JpGauge,
  JpText,
  JpCurrencyNumber,
  JpStackedBarsCard,
  SpinnerLoader,
  parseCurrency
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import WalletAPI from '../../services/API'
import useSocket from '../../utility/hooks/useSocket'
import disabledRefetchSocket from '../../utility/disabledRefetchSocket'
import { useEffect } from 'react'

const TimesGraphs = ({ start_date, end_date }) => {
  const { t } = useTranslation()
  const API = new WalletAPI()
  const {
    data: timeChartData,
    isLoading: timeChartDataisLoading,
    refetch: weekRefetch
  } = useQuery(['timeChartData'], () =>
    API.getWalletTimeChart({
      date__between: `${start_date},${end_date}`
    })
  )
  const {
    data: timeProductivityChart,
    isLoading: timeProductivityChartIsLoading,
    refetch: productivityRefetch
  } = useQuery(['timeProductivityChart'], () =>
    API.getWalletProductivityChart({
      date__between: `${start_date},${end_date}`
    })
  )
  useEffect(() => {
    productivityRefetch()
    weekRefetch()
  }, [start_date, end_date])

  useSocket(
    disabledRefetchSocket(end_date)
      ? {
          '/wallet/time/charts/productivity': {
            fn: value => {
              productivityRefetch()
            }
          },
          '/wallet/time/charts/week': {
            fn: value => {
              weekRefetch()
            }
          }
        }
      : {},
    [end_date]
  )

  return (
    <Row className="mb-2">
      <Col lg="3" sm="12" className="mt-1">
        <JpVerticalBarsCard
          title={t('Time')}
          cardClassName="h-100"
          headerClassName="pb-0"
          footerClassName="py-50"
          footer={
            <>
              <span>
                <small>
                  {t('views.times.totalTimeWorkedAtWeek')}
                  {': '}
                  {parseMinutesToHours(timeChartData?.work_time?.current)}
                </small>
              </span>
              <small className="d-flex">
                <JpPercentageText
                  percentage={
                    /* timeChartData?.work_time?.percent > 0
                      ? */ parseFloat(timeChartData?.work_time?.percent)
                    /* : 0 */
                  }
                />
                {t('views.times.lastPeriod')}
              </small>
            </>
          }
          data={timeChartData?.week
            ?.map(item => parseFloat(item.work_time))
            .reverse()}
          labels={Array.from({ length: 7 }, (_, i) =>
            moment(end_date).subtract(i, 'days').format('dd')
          ).reverse()}
          tooltipFormatter={value => {
            return parseMinutesToHours(value)
          }}
          color="info"
          noData={
            timeChartData?.week
              ?.map(item => parseFloat(item.work_time))
              .reverse()
              .reduce((a, b) => a + b, 0) === 0
          }
          loading={timeChartDataisLoading}
          bodyText={true}
          isToday={moment().format('YYYY-MM-DD') === end_date}
          seriesLabel={
            moment().format('YYYY-MM-DD') === end_date
              ? t('today')
              : moment(end_date).format('dd')
          }
        />
      </Col>

      <Col lg="3" sm="12" className="mt-1">
        <JpGraphCard
          loading={timeProductivityChartIsLoading}
          title={t('views.dashboard.productivity')}
          className="h-100"
          cardBodyClass="d-flex align-items-center justify-content-center"
          graph={<JpGauge value={timeProductivityChart?.current?.variance} />}
          border={true}
          cardFooter={
            <div className="text-center d-flex align-items-center justify-content-start">
              <small className="me-1">
                Total:{' '}
                {parseCurrency(timeProductivityChart?.current?.variance, '')}%
              </small>
              <small>
                <JpPercentageText
                  percentage={timeProductivityChart?.variance?.variance}
                  periodText={t('views.times.lastPeriod')}
                  className="m-0"
                />
              </small>
            </div>
          }
          footerClassName={'py-0 pt-50'}
          subFooter={
            <div className="d-flex align-items-center justify-content-start">
              <small className="text-truncate" id="company-productivity">
                {t('views.times.companyProductivity')}
                {timeProductivityChart?.team?.productivity}%
              </small>
              <UncontrolledTooltip target={'company-productivity'}>
                {timeProductivityChart?.team?.productivity}%
              </UncontrolledTooltip>
              <small>
                <JpPercentageText
                  percentage={timeProductivityChart?.team?.variance?.variance}
                  periodText={t('views.times.lastPeriod')}
                  className="m-0 ms-1"
                />
              </small>
            </div>
          }
          subFooterClassName={'pb-50 pt-0'}
        />
      </Col>

      <Col lg="6" sm="12" className="mt-1">
        <JpStackedBarsCard
          title={t('views.times.productivityvsTimes')}
          data={[
            timeChartData?.week
              ?.map(item => parseFloat(item.percent) / 100)
              ?.reverse(),/* 
            timeChartData?.week
              ?.map(item => parseFloat(item.percent) / 100)
              .map(item => {
                return 1 - item
              })
              ?.reverse() */
          ]}
          labels={Array.from({ length: 7 }, (_, i) =>
            moment(end_date).subtract(i, 'days').format('ddd')
          ).reverse()}
          seriesNames={[t('views.dashboard.productivity')/* , t('Time') */]}
          seriesColors={['green'/* , 'light' */]}
          tooltipFormatters={[
            (value, index) => {
              const totalWeek = timeChartData?.week?.length - 1
              const secondLabel = parseMinutesToHours(
                timeChartData?.week[totalWeek - index]?.work_time
              )
              return `${(value * 100).toFixed(2)} %` + ' / ' + `${t('Time')}` + ' '  + secondLabel
            }/* ,
            (value, index) => {
              const totalWeek = timeChartData?.week?.length - 1
              return parseMinutesToHours(
                timeChartData?.week[totalWeek - index]?.work_time
              )
            } */
          ]}
          loading={timeChartDataisLoading}
          bodyText={true}
          bodyClassName="pb-25"
          height={85}
          classNameCard="mb-0 h-100"
          footerClassName="py-50"
          plugin={true}
          footer={
            <>
              <span>
                <small>
                  {t('views.times.totalProductiveTimeAtWeek')}
                  {': '}
                  {parseMinutesToHours(timeChartData?.productivity?.current)}
                </small>
              </span>
              <small className="d-flex">
                <JpPercentageText
                  percentage={
                    /* timeChartData?.productivity?.percent > 0
                      ? */ parseFloat(timeChartData?.productivity?.percent)
                    /* : 0 */
                  }
                />
                {t('views.times.lastPeriod')}
              </small>
            </>
          }
        />
      </Col>
    </Row>
  )
}

export default TimesGraphs
