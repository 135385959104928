import { createSlice } from '@reduxjs/toolkit'

export const userInfo = createSlice({
  name: 'userInfo',
  initialState: '',
  reducers: {
    setuserInfo: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setuserInfo } = userInfo.actions

export default userInfo.reducer
