// ** Reducers Imports
import { combineReducers } from 'redux'
import layout from "./layout"
import navbar from "./navbar"
import breadcrumbs from "./breadcrumbs"
import getUrlPdf  from './getDocumentPdf'
import userInfo from './userInfo'
import signContractModal from './signContractModal'

const rootReducer = combineReducers({
  navbar,
  layout,
  breadcrumbs,
  getUrlPdf,
  userInfo,
  signContractModal
})

export default rootReducer
