import axios from 'axios'
import { API_URL } from '@src/configs/environment'
import {
  parseQueries,
  parseQueriesLegacy,
  queryString,
  stringQueryByJson
} from '../utility/Query'
import moment from 'moment-timezone'
import { globalHandler } from '@Intelli/utilities'

export default class WalletAPI {
  constructor(baseURL = API_URL) {
    this.API = new axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        lng: localStorage.getItem('i18nextLng')
          ? localStorage.getItem('i18nextLng')
          : 'en',
        timezone: moment.tz.guess(true)
      }
    })

    this.API.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response.data)
        globalHandler(error.response.data)
        return Promise.reject(error)
      }
    )
  }

  async logout() {
    try {
      const response = await this.API.post('v1/auth/logout')
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async getUser() {
    try {
      const response = await this.API.get('v1/auth/user')
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async updateProfile(data) {
    try {
      const response = await this.API.put('v1/auth/user', data)
      return response.data
    } catch (e) {
      return e.response
    }
  }

  async restoreUserAccess(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/users/${data.id}`,
        data.data
      )
      return response
    } catch (error) {
      throw error.response
    }
  }

  async SendEmailCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/email/send',
        { email: data.email },
        {
          headers: { TwoFactorId: data.token }
        }
      )
      return response
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async VerifyEmailCode(code) {
    try {
      await this.API.post('v1/verification/email/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      return false
    }
  }

  async UpdatePassword(data) {
    try {
      const response = await this.API.put('v1/auth/user/password', data.data, {
        headers: { TwoFactorId: data.token }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }

  async SendPhoneCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/phone/send',
        data.phone,
        {
          headers: { TwoFactorId: data.token }
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async VerifyPhoneCode(code) {
    try {
      await this.API.post('v1/verification/phone/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      throw e
    }
  }

  async send2faCode() {
    try {
      const response = await this.API.post('v1/verification/twoFactor/send')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async validate2faCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/twoFactor/validate',
        data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async set2fa(data) {
    try {
      const response = await this.API.post('v1/auth/session/twoFactor', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async changeAdminWorkspace(data) {
    try {
      const response = await this.API.post(
        'v1/auth/session/workspace/admin',
        data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Alejo

  //Get table contracts
  async getContracts(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get table documents
  async getDocuments(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/documents?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get one sign Contract
  async getOneSignContract(id) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts/${id}/sign`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get one contract
  async getOneContract(id) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts/${id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get contract with token
  async getContractSignUrl(token) {
    try {
      const response = await this.API.get(
        'v1/admin/contractors/contract_sign',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Get type of documents
  async getDocumentsType() {
    try {
      const response = await this.API.get('v1/wallet/documents_type')
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Post Documents on wallet table
  async postWalletDocuments(data) {
    try {
      const response = await this.API.post('v1/wallet/documents', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  //Delete documents of wallet tablet
  async deleteDocument(id) {
    try {
      const response = await this.API.delete(`v1/wallet/documents/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardCompany() {
    try {
      const response = await this.API.get(
        'v1/wallet/dashboard/chart/contractos'
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  //User cashout endpoints

  async getUserCashout(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/user/cashout?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardPayrollTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/dashboard/chart/payments/last?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  async getCashoutMethod() {
    try {
      const response = await this.API.get('v1/wallet/cashout_method')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getUserCashoutId(user_cashout_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/user/cashout/${user_cashout_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardPayments() {
    try {
      const response = await this.API.get('v1/wallet/dashboard/chart/payments')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async postPaymentMethod(data) {
    try {
      const response = await this.API.post(`v1/wallet/user/cashout`, data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async putPaymentMethod(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/user/cashout/${data.id}`,
        data.data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async putFavoriteMethod(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/user/cashout/${data.id}/is_favorite`,
        data.data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deletePaymentMethod(user_cashout_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/user/cashout/${user_cashout_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getAccountTypes(query = '') {
    try {
      const response = await this.API.get(
        `v1/wallet/account_type/search${query && `?query=${query}`}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  async getCryptoNames(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/cashout_method/search/cryptoname?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getCryptoNetwork(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/cashout_method/search/network?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getBanks(query = '') {
    try {
      const response = await this.API.get(
        `v1/wallet/banks/search${query && `?${queryString(query)}`}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getBanksList() {
    try {
      const response = await this.API.get(`v1/wallet/banks/countries`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getContractorPayroll(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/payroll?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editProfilePic(data) {
    try {
      const formData = new FormData()
      formData.append('file', data)
      const response = await this.API.post(`v1/auth/user/photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async profileProgress() {
    try {
      const response = await this.API.get(`v1/wallet/user/profile/progress`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async documentProgress() {
    try {
      const response = await this.API.get(`v1/wallet/user/documents/progress`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorTimes(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletTimeChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/week?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletProductivityChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/productivity?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorDetailTimes(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/detail?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletProductivityDetailChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/productivity/detail?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getUserProfile() {
    try {
      const response = await this.API.get(`v1/wallet/user`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getDashboardApps() {
    try {
      const response = await this.API.get(`v1/wallet/dashboard/apps/urls`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorWeeklyWorkedHours(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/week?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorPermissions(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/contractors?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorPermission(contractor_permission_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/contractors/${contractor_permission_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deletePermission(permission_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/time/permissions/contractors/${permission_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addContractorPermission(data) {
    try {
      const response = await this.API.post(
        `v1/wallet/time/permissions/contractors`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async editContractorPermission(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/time/permissions/contractors/${data.permission_contractor_id}`,
        data.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async getAvailablePermissions(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/search?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjects(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/contractor?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsAllBudgetGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsAllEstimatedHoursGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsAllDeviationGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsAllStatusGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_projects/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsHours(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/contractor/hours?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsTimeAllBudgetGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/contractor/projects?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsTimeAllEstimatedHoursGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/contractor/projects?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsTimeAllDeviationGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/contractor/projects?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsTimeAllStatusGraph(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_projects/contractor/projects?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsDetailBudgetGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/contractor/projects/${project_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsDetailEstimatedHoursGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/contractor/projects/${project_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsDetailDeviationGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/contractor/projects/${project_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsDetailStatusTasksGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_tasks/contractor/projects/${project_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectInfo(project_id) {
    try {
      const response = await this.API.get(`v1/wallet/project/${project_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectKanbanColumns(project_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/${project_id}/columns`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectKanbanTasks(project_column_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/${project_column_id}/column?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async moveTaskKanban(data, task_id) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/${task_id}/moved`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editProject(data, porject_id) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/${porject_id}`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createColumnKanban(data, project_id) {
    try {
      const response = await this.API.post(
        `v1/wallet/project/${project_id}/column`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editColumnKanban(data, project_column_id) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/${project_column_id}/column`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteColumnKanban(project_column_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/project/${project_column_id}/column`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteAllTasksInColumnKanban(project_column_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/project/${project_column_id}/column/tasks`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTasksTable(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/${task_id}/tasks?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async archiveProject(data, porject_id) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/${porject_id}/status`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskInfo(task_id) {
    try {
      const response = await this.API.get(`v1/wallet/project/task/${task_id}`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createTaskKanban(data) {
    try {
      const response = await this.API.post(`v1/wallet/project/task`, data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorsWithProjectsInCompany(client_id, query = '') {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/search/${client_id}?${
          query && `&query=${query}`
        }`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getColumnOptionsSelect(project_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/${project_id}/columns_select`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editTaskKanban(data, task_id) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/task/${task_id}`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async approveDisapprovedTask(task_id, data) {
    try {
      const response = await this.API.post(
        `v1/wallet/project/${task_id}/approve`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async archiveTask(task_id, data) {
    try {
      const response = await this.API.post(
        `v1/wallet/project/${task_id}/archived`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectDetailTimeBudgetGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/contractor/projects/${project_id}/hours?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectDetailTimeEstimatedHoursGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/contractor/projects/${project_id}/hours?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectDetailTimeDeviationGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/contractor/projects/${project_id}/hours?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectDetailTimeStatusGraph(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_tasks/contractor/projects/${project_id}/hours?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getProjectsDetailsTime(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/contractor/project/${project_id}/hours?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getArchivedTasks(project_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/${project_id}/tasks_archived?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailBudgetGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/task/${task_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailEstimatedHoursGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/task/${task_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailStatusSubtasksGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_checks/task/${task_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailDeviationGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/task/${task_id}?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskChecklist(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/task/${task_id}/checks?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addChecklistItem(data) {
    try {
      const response = await this.API.post(`v1/wallet/project/check_list`, data)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editChecklistItem(checklist_item_id, data) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/${checklist_item_id}/check_list`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteChecklistItem(checklist_item_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/project/${checklist_item_id}/check_list`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addAttachmentInTask(data, task_id) {
    try {
      const formData = new FormData()

      data.file.forEach(file => {
        formData.append('file', file)
      })
      const response = await this.API.post(
        `v1/wallet/project/attachment/${task_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskAttachments(task_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/task/${task_id}/attachments`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteAttachmentInTask(data) {
    try {
      const response = await this.API.delete(
        `v1/wallet/project/attachment/delete`,
        { data }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskComments(task_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/task/${task_id}/comments`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addCommentInTask(data) {
    try {
      const response = await this.API.post(
        `v1/wallet/project/task/comment`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deleteCommentInTask(comment_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/project/task/${comment_id}/comment`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editCommentInTask(comment_id, data) {
    try {
      const response = await this.API.put(
        `v1/wallet/project/task/${comment_id}/comment`,
        data
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getActivityTask(task_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/task/${task_id}/activity`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursBudgetGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/budget/task/${task_id}/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursEstimatedHoursGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/estimated_hours/task/${task_id}/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursSubtaskStatusGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/status_checks/task/${task_id}/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursDeviationGraph(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/dashboard/desviations/task/${task_id}/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursDays(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/time/task/${task_id}/contractor?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getTaskDetailHoursDaysMarkings(task_id, query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/project/time/task/${task_id}/contractor/days?${stringQueryByJson(
          query
        )}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async createImageInTextRich(data) {
    try {
        const formData = new FormData()
        formData.append('file', data)
        const response = await this.API.post(
            `v1/wallet/project/images/rich`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return response
    } catch (e) {
        throw e
    }
}

async deleteImageInTextRich(data) {
    try {
        const response = await this.API.delete(`v1/wallet/project/images/delete`, {data})
        return response.data
    } catch (e) {
        throw e
    }
}
}
