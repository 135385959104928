import { createSlice } from '@reduxjs/toolkit'

export const breadcrumbs = createSlice({
  name: 'breadcrumbs',
  initialState: {},
  reducers: {
    addBreadcrumbs: (state, action) => {
      state[action.payload.id] = action.payload.value
    }
  }
})

export const { addBreadcrumbs } = breadcrumbs.actions

export default breadcrumbs.reducer
