// ** Dropdowns Imports
import CustomUserDropdown from './CustomUserDropdown'
import { IntlDropdown } from '@Intelli/utilities'
import CustomNotificationDropdown from './CustomNotificationDropdown'
import SettingsButton from './SettingsButton'
import CustomMenu from './CustomMenu'
import ValidatedAccAlert from './ValidatedAccAlert'
const CustomNavbarUser = ({ menuData }) => {
  return (
    <>
      <ul className="col nav navbar-nav align-items-center justify-content-center ms-5 ps-5">
        {/* <IntlDropdown /> */}
        {/* <CustomNotificationDropdown />
      <SettingsButton /> */}
        <CustomMenu menuData={menuData} />
      </ul>
      <ul className="col-xl-3 nav navbar-nav align-items-center justify-content-end pe-10">
        <ValidatedAccAlert />
        <CustomUserDropdown />
      </ul>
    </>
  )
}
export default CustomNavbarUser
