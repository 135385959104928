// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

import { language$ } from '@Intelli/utilities'

import { useEffect } from 'react'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n, t } = useTranslation()

  // ** Vars
  //const langObj = {
  //  en: 'English',
  //  es: 'Spanish'
  //}

  useEffect(() => {
    const subscription = language$.subscribe((lang) => {
      /* console.log('lang', lang) */
      if (lang.lang !== i18n.language) {
        i18n.changeLanguage(lang.lang)
      }
    })
    return () => { subscription.unsubscribe() }
  }, [])

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    i18n.changeLanguage(lang)
    language$.next({ lang: i18n.language })
  }

  return (
    <UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link' onClick={e => e.preventDefault()}>
        <ReactCountryFlag
          svg
          className='country-flag flag-icon'
          countryCode={i18n.language === 'en' ? 'us' : i18n.language}
        />
        {/*<span className='selected-language'>{langObj[i18n.language]}</span>*/}
      </DropdownToggle>
      <DropdownMenu className='mt-0' end>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'en')}>
          <ReactCountryFlag className='country-flag' countryCode='us' svg />
          <span className='ms-1'>{t('en')}</span>
        </DropdownItem>
        <DropdownItem href='/' tag='a' onClick={e => handleLangUpdate(e, 'es')}>
          <ReactCountryFlag className='country-flag' countryCode='es' svg />
          <span className='ms-1'>{t('es')}</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown