// ** Reducers Imports
import { combineReducers } from 'redux'
import layout from './layout'
import navbar from './navbar'
import breadcrumbs from './breadcrumbs'
import getUrlPdf from './getDocumentPdf'
import userInfo from './userInfo'
import signContractModal from './signContractModal'
import isProjectLeader from './isProjectLeader'
import projectInfoRow from './projectInfoRow'
import taskInfoRow from './taskInfoRow'
import isAllDataProject from './isAllDataProject'

const rootReducer = combineReducers({
  navbar,
  layout,
  breadcrumbs,
  getUrlPdf,
  userInfo,
  signContractModal,
  isProjectLeader,
  projectInfoRow,
  taskInfoRow,
  isAllDataProject,
})

export default rootReducer
