// ** Redux Imports
import rootReducer from "./rootReducer"
import { configureStore } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const transform = createTransform(null, (state, key) => {
  // console.log('key', key)
  // console.log('state', state)
  return state
})

const persistConfig = {
  key: 'wallet',
  version: 1,
  storage,
  transforms: [transform],
  blacklist: ['layout'],
  whitelist: [
    'navbar',
    'breadcrumbs',
    'getUrlPdf',
    'routes',
    'userInfo',
    'signContractModal'
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

let persistor = persistStore(store)

export { store, persistor }
