import { createSlice } from "@reduxjs/toolkit";

const initialState= ''
export const getUrlPdfSlice = createSlice({
    name: 'getUrlPdf',
    initialState,
    reducers:{
        setUrlPdf: (state, action)=>{
            return state = action.payload
        }
    }
})
export const {setUrlPdf} = getUrlPdfSlice.actions
export default getUrlPdfSlice.reducer