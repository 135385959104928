import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const signContractModal = createSlice({
  name: 'SignContractModal',
  initialState,
  reducers: {
    setSignContractModal: (state, action) => {
      return (state = action.payload)
    }
  }
})

export const { setSignContractModal } = signContractModal.actions

export default signContractModal.reducer
