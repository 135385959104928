//Remove queries with empty value
export function normalizeQueries(queries = []) {
  const aQueries = []
  for (const query of queries) {
    if (query.value !== '') {
      aQueries.push(query)
    }
  }
  return aQueries
}
/**
 * @Description Convierte un arreglo de queries en un url query string
 * @param {Array} queries
 * @returns
 */
export function parseQueries(queries = []) {
  let query = ''
  let i = 0
  for (const item of queries) {
    if (typeof item.value === 'undefined' || item.value === '') {
      continue
    }
    if (typeof item.operator !== 'undefined') {
      switch (item.operator) {
        case '$like':
        case '$iLike':
          query += `&${item.field}[${item.operator}]=${item.value}%`
          break
        case '$or':
          query += `&$or[${i}][${item.field}]=${item.value}`
          i++
          break
        case '$or_opt':
          query += `&$or[${i}][${item.field}][${item.suboperator}]=${item.value}`
          i++
          break
        case '=':
        case '$equals':
          query += `&${item.field}=${item.value}`
          break
        case '$select':
          query += `&${item.field}[]=${item.value}`
          break
        case '$sort':
          query += `&${item.operator}[${item.field}]=${item.value}`
          break
        default:
          query += `&${item.field}[${item.operator}]=${
            item.type === 'date'
              ? item.value.format('YYYY-MM-DDTHH:mm:ssZ')
              : item.value
          }`
      }
    } else {
      query += `&${item.field}=${item.value}`
    }
  }
  return query
}

export function parseQueriesLegacy(queries = []) {
  let query = ''

  for (const item of normalizeQueries(queries)) {
    if (typeof item.operator !== 'undefined') {
      query += `&${item.field}__${item.operator}=${item.value}`
    } else {
      query += `&${item.field}=${item.value}`
    }
  }
  return query
}

export function queryString(params) {
  return Object.keys(params)
    .filter(key => params[key])
    .map(key => key + '=' + params[key])
    .join('&')
}

export const stringQueryByJson = params =>
  Object.keys(params)
    .filter(key => !['', undefined].includes(params[key]))
    .map(key => key + '=' + params[key])
    .join('&')
