// ** React Imports
import { Outlet } from 'react-router-dom'
import Layout from '@layouts/HorizontalLayout'
import navigation from '@src/navigation/horizontal'
import { Col, Row } from 'reactstrap'
import logo from '@src/assets/images/logo/logo_white_horizontal.svg'
import CustomNavbar from '../components/navbar/CustomNavbar'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import WalletAPI from '../services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  user$,
  getUserOperatingSystem,
  SpinnerLoader
} from '@Intelli/utilities'

const CustomFooter = () => {
  const { t } = useTranslation()

  const API = new WalletAPI()
  const os = getUserOperatingSystem()

  const { mutate: downloadApp, isLoading: appsDataArrayIsLoading } =
    useMutation(data => API.getDashboardApps(data), {
      onSuccess: d => {
        const appToDownload = d?.find(i => i.name === os.toUpperCase())
        window.location.href = appToDownload?.url
      }
    })

  if (appsDataArrayIsLoading) return <SpinnerLoader />

  return (
    <>
      <Row className="pt-50 pb-50">
        <img
          className="border-end pe-1 border-2"
          style={{ maxWidth: '135px' }}
          src={logo}
          alt="logo"
        />
        <Col>
          <div>
            <small className="footer-text">
              {t('Footer-TextOne')} {new Date().getFullYear()}
              {t('Footer-TextTwo')}
            </small>
          </div>
        </Col>
        <Col className="text-end">
          <small
            /* onClick={() => (window.location.href = appToDownload?.url)} */
            onClick={() => downloadApp()}
            className="footer-text seeDoc cursor-pointer"
          >
            {t('downloadApp')}
          </small>
          {/* {i18n.language !== 'es' ? (
            <Link
              target="_blank"
              className="footer-text seeDoc cursor-pointer text-white"
              to={'https://joobpay.com/user-manual/'}
            >
              
              {t('userManual')}
            </Link>
          ) : (
            <Link
              target="_blank"
              className="footer-text seeDoc cursor-pointer text-white"
              to={'https://joobpay.com/es/manual-de-usuario/'}
            >
              
              {t('userManual')}
            </Link>
          )} */}
        </Col>
      </Row>
    </>
  )
}

const HorizontalLayout = props => {
  const API = new WalletAPI()
  const [menuData, setMenuData] = useState([])

  const {} = useQuery(['profileInfo'], () => API.getUserProfile(), {
    enabled: !!user$?.value?.company?.contractor,
    onSuccess: d => {
      let updatedMenuData = [...navigation]

      if (!d.time_active) {
        updatedMenuData = updatedMenuData.filter(i => i.id !== 'times')
      }

      if (!d.projects.active) {
        updatedMenuData = updatedMenuData.filter(i => i.id !== 'projects')
      }

      setMenuData(updatedMenuData)
      localStorage.setItem('menuData', JSON.stringify(updatedMenuData))
      
    }
  })

  useEffect(() => {
    if (!!!user$?.value?.company?.contractor) {
      setMenuData([...navigation.filter(i => i.id !== 'times' && i.id !== 'projects')])
    }
  }, [])

  return (
    <Layout
      menuData={menuData}
      footer={<CustomFooter />}
      navbar={props => <CustomNavbar menuData={menuData} {...props} />}
      {...props}
    >
      <Outlet />
    </Layout>
  )
}

export default HorizontalLayout
