import { socket } from '@src/services/SocketConfig'
import { useEffect, useState } from 'react'

const useSocket = (callbacks, dependency = []) => {
  useEffect(() => {
    const idSocket = socket.subscribe(callbacks)

    return () => {
      socket.unsubscribe(idSocket)
    }
  }, dependency)
}

export default useSocket
