import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faFileInvoiceDollar, faClock } from '@fortawesome/pro-solid-svg-icons'
import { Home } from 'react-feather'


const main_route = '/wallet'

export default [
/*   {
    id: 'home',
    title: 'HOME',
    icon: <Home size={20} />,
    navLink: `${main_route}/home`
  }, */
  {
    id: 'times',
    title: 'TIMES',
    icon: <FontAwesomeIcon icon={faClock} size="lg" />,
    navLink: `${main_route}/times`
  },
  {
    id: 'documents',
    title: 'DOCUMENTS',
    icon: <FontAwesomeIcon icon={faFileAlt} size="lg" />,
    navLink: `${main_route}/documents`
  },
  {
    id: 'payments',
    title: 'PAYMENTS',
    icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="lg" />,
    navLink: `${main_route}/payments`
  }
]
