import { SOCKET_IO_URL, SOCKET_IO_HANDSHAKE } from '@src/configs/environment'
import { io } from 'socket.io-client'

export class socket {
  static load() {
    if (!socket.SOCKET) {
      socket.authToken = JSON.parse(localStorage.getItem('token'))
      socket.SOCKET = io(SOCKET_IO_URL, {
        /* path: SOCKET_IO_HANDSHAKE, */
        transports: ['websocket', 'polling'],
        auth: {
          token: socket.authToken
        }
      })
    }

    this.subscriptions = {}
    /* {
            topic //url topico: {
                '21212121-sdsa12121' //subscriptionId: {
                    time: 1 || undefined //milisegundos en timeout para esta fn --- viene de cuando llamo al hook
                    fn: ()=>{} fn //la funcion a ejecutar cuando se notifique,
                    timeout_id: setTimeout(),
                    date: date ---> se usa para ver cual es el ultimo a ejecutar
                } 
            }
        } */

    const defaultTime = 300
    socket.SOCKET.on('notification', value => {
      /* value={
                topic: '/productivity/dashboard',
                payload: {payload que envia el back}
            } */

      /* Object.values(this.subscriptions[value.topic] = [{
                un objeto como el de this.subscriptions
            }] */

      Object.values(this.subscriptions[value.topic] || {}).forEach(item => {
        const cbTime = item.time ?? defaultTime
        if (
          item?.timeout_id &&
          new Date().getTime() - item.date.getTime() < cbTime
        ) {
          clearTimeout(item?.timeout_id)
        } else {
          item.date = new Date()
        }
        item.timeout_id = setTimeout(() => {
          item.fn(value)
        }, cbTime)
      })
    })
  }

  static checkToken = () => {
    if (socket.authToken === JSON.parse(localStorage.getItem('token'))) return

    socket.load()
  }

  static subscribe(callbacks) {
    socket.checkToken()
    const subscriptionId = crypto.randomUUID()
    const toSubscribe = Object.entries(callbacks).filter(([topic, item]) => {
      if (!this.subscriptions[topic]) {
        this.subscriptions[topic] = {}
      }

      this.subscriptions[topic][subscriptionId] = {
        ...item,
        date: new Date()
      }
      return true
    })
    if (toSubscribe.length > 0) {
      socket.SOCKET.emit(
        'subscribe',
        toSubscribe.map(item => item[0])
      )
    }

    return subscriptionId
  }

  static unsubscribe(subscriptionId) {
    const toUnsubscribe = Object.keys(this.subscriptions).filter(topic => {
      if (this.subscriptions[topic]?.[subscriptionId]) {
        delete this.subscriptions[topic][subscriptionId]

        return Object.keys(this.subscriptions[topic]).length === 0
      }

      return false
    })

    if (toUnsubscribe.length > 0) {
      socket.SOCKET.emit('unsubscribe', toUnsubscribe)
    }
  }
}

socket.load()
