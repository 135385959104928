import axios from 'axios'
import { API_URL } from '@src/configs/environment'
import {
  parseQueries,
  parseQueriesLegacy,
  queryString,
  stringQueryByJson
} from '../utility/Query'
import moment from 'moment-timezone'
import { globalHandler } from '@Intelli/utilities'

export default class WalletAPI {
  constructor(baseURL = API_URL) {
    this.API = new axios.create({
      baseURL,
      withCredentials: true,
      headers: {
        lng: localStorage.getItem('i18nextLng')
          ? localStorage.getItem('i18nextLng')
          : 'en',
        timezone: moment.tz.guess(true)
      }
    })

    this.API.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response.data)
        globalHandler(error.response.data)
        return Promise.reject(error)
      }
    )
  }

  async logout() {
    try {
      const response = await this.API.post('v1/auth/logout')
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async getUser() {
    try {
      const response = await this.API.get('v1/auth/user')
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  async updateProfile(data) {
    try {
      const response = await this.API.put('v1/auth/user', data)
      return response.data
    } catch (e) {
      return e.response
    }
  }

  async restoreUserAccess(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/users/${data.id}`,
        data.data
      )
      return response
    } catch (error) {
      throw error.response
    }
  }

  async SendEmailCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/email/send',
        { email: data.email },
        {
          headers: { TwoFactorId: data.token }
        }
      )
      return response
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async VerifyEmailCode(code) {
    try {
      await this.API.post('v1/verification/email/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      return false
    }
  }

  async UpdatePassword(data) {
    try {
      const response = await this.API.put('v1/auth/user/password', data.data, {
        headers: { TwoFactorId: data.token }
      })
      return response.data
    } catch (e) {
      throw e
    }
  }

  async SendPhoneCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/phone/send',
        data.phone,
        {
          headers: { TwoFactorId: data.token }
        }
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async VerifyPhoneCode(code) {
    try {
      await this.API.post('v1/verification/phone/validate', code.data, {
        headers: { TwoFactorId: code.token }
      })
      return true
    } catch (e) {
      throw e
    }
  }

  async send2faCode() {
    try {
      const response = await this.API.post('v1/verification/twoFactor/send')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async validate2faCode(data) {
    try {
      const response = await this.API.post(
        'v1/verification/twoFactor/validate',
        data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async set2fa(data) {
    try {
      const response = await this.API.post('v1/auth/session/twoFactor', data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async changeAdminWorkspace(data) {
    try {
      const response = await this.API.post(
        'v1/auth/session/workspace/admin',
        data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Alejo

  //Get table contracts
  async getContracts(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get table documents
  async getDocuments(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/documents?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get one sign Contract
  async getOneSignContract(id) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts/${id}/sign`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get one contract
  async getOneContract(id) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/contracts/${id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  //Get contract with token
  async getContractSignUrl(token) {
    try {
      const response = await this.API.get(
        'v1/admin/contractors/contract_sign',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Get type of documents
  async getDocumentsType() {
    try {
      const response = await this.API.get('v1/wallet/documents_type')
      return response.data
    } catch (error) {
      throw error
    }
  }

  //Post Documents on wallet table
  async postWalletDocuments(data) {
    try {
      const response = await this.API.post('v1/wallet/documents', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (e) {
      throw e.response
    }
  }

  //Delete documents of wallet tablet
  async deleteDocument(id) {
    try {
      const response = await this.API.delete(`v1/wallet/documents/${id}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardCompany() {
    try {
      const response = await this.API.get(
        'v1/wallet/dashboard/chart/contractos'
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  //User cashout endpoints

  async getUserCashout(query = {}) {
    try {
      const response = await this.API.get(`v1/wallet/user/cashout?${stringQueryByJson(query)}`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardPayrollTable(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/dashboard/chart/payments/last?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  async getCashoutMethod() {
    try {
      const response = await this.API.get('v1/wallet/cashout_method')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getUserCashoutId(user_cashout_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/user/cashout/${user_cashout_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getDashboardPayments() {
    try {
      const response = await this.API.get('v1/wallet/dashboard/chart/payments')
      return response.data
    } catch (error) {
      throw error
    }
  }

  async postPaymentMethod(data) {
    try {
      const response = await this.API.post(`v1/wallet/user/cashout`, data)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async putPaymentMethod(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/user/cashout/${data.id}`, data.data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async putFavoriteMethod(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/user/cashout/${data.id}/is_favorite`, data.data
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async deletePaymentMethod(user_cashout_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/user/cashout/${user_cashout_id}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getAccountTypes(query = '') {
    try {
      const response = await this.API.get(
        `v1/wallet/account_type/search${query && `?query=${query}`}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  async getCryptoNames(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/cashout_method/search/cryptoname?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getCryptoNetwork(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/cashout_method/search/network?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }
  

  async getBanks(query = '') {
    try {
      const response = await this.API.get(
        `v1/wallet/banks/search${query && `?${queryString(query)}`}`
      )
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getBanksList() {
    try {
      const response = await this.API.get(`v1/wallet/banks/countries`)
      return response.data
    } catch (error) {
      throw error
    }
  }

  async getContractorPayroll(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/contractors/payroll?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async editProfilePic(data) {
    try {
      const formData = new FormData()
      formData.append('file', data)
      const response = await this.API.post(`v1/auth/user/photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response
    } catch (e) {
      throw e
    }
  }

  async profileProgress() {
    try {
      const response = await this.API.get(`v1/wallet/user/profile/progress`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async documentProgress() {
    try {
      const response = await this.API.get(`v1/wallet/user/documents/progress`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorTimes(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletTimeChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/week?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletProductivityChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/productivity?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorDetailTimes(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/detail?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getWalletProductivityDetailChart(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/productivity/detail?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getUserProfile() {
    try {
      const response = await this.API.get(`v1/wallet/user`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getDashboardApps() {
    try {
      const response = await this.API.get(`v1/wallet/dashboard/apps/urls`)
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorWeeklyWorkedHours(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/chart/week?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorPermissions(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/contractors?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async getContractorPermission(contractor_permission_id) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/contractors/${contractor_permission_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async deletePermission(permission_id) {
    try {
      const response = await this.API.delete(
        `v1/wallet/time/permissions/contractors/${permission_id}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }

  async addContractorPermission(data) {
    try {
      const response = await this.API.post(
        `v1/wallet/time/permissions/contractors`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async editContractorPermission(data) {
    try {
      const response = await this.API.put(
        `v1/wallet/time/permissions/contractors/${data.permission_contractor_id}`,
        data.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response
    } catch (e) {
      throw e
    }
  }

  async getAvailablePermissions(query = {}) {
    try {
      const response = await this.API.get(
        `v1/wallet/time/permissions/search?${stringQueryByJson(query)}`
      )
      return response.data
    } catch (e) {
      throw e
    }
  }
}
