import {
  JpButton,
  JpModal,
  JpLabel,
  JpDatePicker,
  JpText,
  SweetModal,
  JpFileInput,
  JpAvatar
} from '@Intelli/utilities'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import WalletAPI from '../../services/API'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip
} from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadFile } from '@src/utility/Files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileText } from 'react-feather'
import { faListCheck, faX } from '@fortawesome/pro-solid-svg-icons'

const ModifyPermissionModal = ({
  isOpen,
  toggle,
  editData = {},
  refetch,
  approval = false,
  view_only = false,
  contractor_id,
  setEditData
}) => {
  const { t } = useTranslation()
  const API = new WalletAPI()
  const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [files, setFiles] = useState([])
  const [fileInvalid, setFileInvalid] = useState(false)

  const PermissionsSchema = yup.object().shape({
    permission_id: yup.object().nullable().required('InputRequired'),
    start_date: yup.string().required('InputRequired'),
    end_date: yup.string().required('InputRequired'),
    description: yup.string().max(200, 'maxTxt').required('InputRequired')
  })
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      start_date,
      end_date
    },
    resolver: yupResolver(PermissionsSchema)
  })

  const { data: permissionData } = useQuery(
    ['getPermission', editData?.contractor_permission_id, isOpen],
    () => API.getContractorPermission(editData?.contractor_permission_id),
    {
      enabled: !!editData?.contractor_permission_id,
      onSuccess: res => {
        res.result.support_media_location &&
          setFiles([{ name: res.result.support_media_location }])
        setStartDate(res?.result?.start_date)
        setEndDate(res?.result?.end_date)
        reset({
          permission_id: {
            value: res?.result?.permission_id,
            label: res?.result?.permission_name
          },
          description: res?.result?.description,
          start_date: moment(res?.result?.start_date).format('YYYY-MM-DD'),
          end_date: moment(res?.result?.end_date).format('YYYY-MM-DD')
        })
      }
    }
  )

  const onClose = () => {
    setEditData({})
    setFiles([])
    setFileInvalid(false)
    refetch()
    toggle()
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
  }

  const { mutate: addPermission, isLoading: addLoading } = useMutation(
    data => API.addContractorPermission(data),
    {
      onSuccess: () => {
        setFiles([])
        onClose()
        SweetModal(
          'success',
          t('Success'),
          t('views.permissions.addPermission'),
          t('Ok')
        )
      }
    }
  )

  const { mutate: editPermission, isLoading: editLoading } = useMutation(
    data => API.editContractorPermission(data),
    {
      onSuccess: () => {
        setFiles([])
        onClose()
        SweetModal(
          'success',
          t('Success'),
          t('views.permissions.editPermissionSuccess'),
          t('Ok')
        )
      }
    }
  )

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }

  const renderFileSize = size => {
    if (Math.round(size / 100) / 10 > 1000) {
      return `${(Math.round(size / 100) / 10000).toFixed(1)} MB`
    } else {
      return `${(Math.round(size / 100) / 10).toFixed(1)} KB`
    }
  }

  const fileList = files?.map((file, index) => (
    <ListGroupItem
      key={`${file.name}-${index}`}
      className="d-flex align-items-center justify-content-between text-truncate"
    >
      <div className="file-details d-flex align-items-center col-10 text-truncate">
        <div className="file-preview me-1 text-truncate">
          <FileText size="28" />
        </div>
        <div className="col-10 text-truncate">
          <p className="file-name mb-0 text-truncate">{file.name}</p>
          {file.size && (
            <p className="file-size mb-0">{renderFileSize(file.size)}</p>
          )}
        </div>
      </div>
      <Button
        color="danger"
        outline
        size="sm"
        className="btn-icon ms-75"
        onClick={() => handleRemoveFile(file)}
      >
        <FontAwesomeIcon icon={faX} />
      </Button>
    </ListGroupItem>
  ))
  const onSubmit = formData => {
    const sameFile =
      files[0]?.name === permissionData?.result?.support_media_location

    const dataToSend =
      files?.length > 0
        ? {
            ...formData,
            permission_id: formData?.permission_id?.value,
            file: sameFile ? [] : files[0]
          }
        : {
            ...formData,
            permission_id: formData?.permission_id?.value
          }
    if (editData && !!editData?.contractor_permission_id) {
      SweetModal(
        'warning',
        t('views.permissions.editPermission'),
        t('views.permissions.editPermissionTxt'),
        t('Ok'),
        ({ isConfirmed }) => {
          if (isConfirmed) {
            editPermission({
              permission_contractor_id: editData?.contractor_permission_id,
              data: dataToSend
            })
          }
        },
        { showCancelButton: true, cancelButtonText: t('CANCEL') }
      )
    } else {
      addPermission({ ...dataToSend, contractor_id })
    }
  }

  const stateColors = {
    EJECUTED: {
      color: 'fa-solid fa-clipboard-list-check',
      icon: false
    },
    PROCESSED: {
      color: 'fa-solid fa-clipboard-user',
      icon: false
    },
    APPROVED: {
      color: 'light-green',
      icon: 'fa-solid fa-clipboard-list-check'
    },
    PENDING: {
      color: 'light-warning',
      icon: 'fa-solid fa-clipboard-user'
    },
    REJECTED: {
      color: 'light-danger',
      icon: 'fa-solid fa-clipboard'
    }
  }

  useEffect(() => {
    if (!editData?.contractor_permission_id) {
      reset({
        start_date,
        end_date,
        description: '',
        permission_id: null
      })
    }
  }, [isOpen])

  return (
    <JpModal
      isOpen={isOpen}
      toggle={onClose}
      headerContent={
        view_only ? (
          <h4 className="mt-25">{t('views.permissions.permissionDetail')}</h4>
        ) : (
          <h4>
            {editData?.contractor_permission_id
              ? t('views.permissions.editPermissions')
              : t('views.permissions.addPermissions')}
          </h4>
        )
      }
      bodyContent={
        <Row className="mb-2 mt-1">
          <Col sm="12">
            {!approval && !view_only ? (
              <JpAsyncSelectController
                control={control}
                errors={errors}
                name="permission_id"
                title={`${t('views.permissions.permission')}*`}
                loadOptions={async value => {
                  const res = await API.getAvailablePermissions({
                    query: value
                  }).then(res => {
                    return res?.data?.map(item => {
                      return {
                        value: item?.id,
                        label: item?.name
                      }
                    })
                  })
                  return res
                }}
              />
            ) : (
              <Row>
                <Col xs="6" className="d-flex align-items-center">
                  <div>
                    <JpAvatar
                      className="rounded me-1"
                      imgClassName="cursor-default"
                      color={'light-info'}
                      icon={
                        <FontAwesomeIcon
                          icon={`fa-solid fa-${permissionData?.result?.permission_icon}`}
                        />
                      }
                      /* size={'lg'} */
                    />
                  </div>
                  <div className="col-8">
                    <JpLabel labelOptions={{ for: 'permission_id' }}>
                      {t('views.permissions.permission')}
                    </JpLabel>
                    <JpText
                      id="permission"
                      type="span-table-text"
                      children={getValues('permission_id')?.label || ''}
                    />
                    <UncontrolledTooltip target={'permission'}>
                      {getValues('permission_id')?.label || ''}
                    </UncontrolledTooltip>
                  </div>
                </Col>
                <Col xs="6" className="d-flex align-items-center">
                  <div>
                    <JpAvatar
                      className="rounded me-1"
                      imgClassName="cursor-default"
                      color={stateColors[permissionData?.result?.state]?.color}
                      icon={
                        <FontAwesomeIcon
                          icon={
                            stateColors[permissionData?.result?.state]?.icon
                          }
                        />
                      }
                      /* size={'lg'} */
                    />
                  </div>
                  <div>
                    <JpLabel labelOptions={{ for: 'state' }}>
                      {t('views.permissions.state')}
                    </JpLabel>
                    <JpText
                      type="span-table-text"
                      children={
                        t(
                          `views.permissions.states.${permissionData?.result?.state}`
                        ) || ''
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col sm="6" className="mt-1">
            {!approval && !view_only ? (
              <>
                <JpLabel labelOptions={{ for: 'start_date' }}>
                  {t('views.permissions.startDate')}
                </JpLabel>
                <Controller
                  control={control}
                  name="start_date"
                  render={({ field }) => (
                    <JpDatePicker
                      id="start_date"
                      maxDate={end_date}
                      setPicker={date => {
                        setStartDate(moment(date).format('YYYY-MM-DD'))
                        field.onChange(date ? date.format('YYYY-MM-DD') : '')
                      }}
                      className={`enabled-joobpay ${
                        errors.end_date ? 'is-invalid' : 'mb-1'
                      }`}
                      {...field}
                    />
                  )}
                />
              </>
            ) : (
              <div className="d-flex align-items-center">
                <div>
                  <JpAvatar
                    className="rounded me-1"
                    imgClassName="cursor-default"
                    color={'light-primary'}
                    icon={<FontAwesomeIcon icon={'fa-solid fa-calendar'} />}
                    /* size={'lg'} */
                  />
                </div>
                <div>
                  <JpLabel labelOptions={{ for: 'start_date' }}>
                    {t('views.permissions.startDate').slice(0, -1)}
                  </JpLabel>
                  <JpText
                    type="span-table-text"
                    children={getValues('start_date') || ''}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col sm="6" className="mt-1">
            {!approval && !view_only ? (
              <>
                <JpLabel labelOptions={{ for: 'end_date' }}>
                  {t('views.permissions.endDate')}
                </JpLabel>
                <Controller
                  control={control}
                  name="end_date"
                  render={({ field }) => (
                    <JpDatePicker
                      id="end_date"
                      minDate={moment(start_date).format('YYYY-MM-DD')}
                      setPicker={date => {
                        setEndDate(moment(date).format('YYYY-MM-DD'))
                        field?.onChange(date ? date.format('YYYY-MM-DD') : '')
                      }}
                      className={`enabled-joobpay ${
                        errors.end_date ? 'is-invalid' : 'mb-1'
                      }`}
                      {...field}
                    />
                  )}
                />
              </>
            ) : (
              <div className="d-flex align-items-center">
                <div>
                  <JpAvatar
                    className="rounded me-1"
                    imgClassName="cursor-default"
                    color={'light-primary'}
                    icon={<FontAwesomeIcon icon={'fa-solid fa-calendar'} />}
                    /* size={'lg'} */
                  />
                </div>
                <div>
                  <JpLabel labelOptions={{ for: 'end_date' }}>
                    {t('views.permissions.endDate').slice(0, -1)}
                  </JpLabel>
                  <JpText
                    type="span-table-text"
                    children={getValues('end_date') || ''}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col sm="12" className="my-1">
            {!view_only ? (
              <JpTextAreaController
                name="description"
                optionsInput={{
                  placeholder: t('views.permissions.description')
                }}
                control={control}
                errors={errors}
                title={t('views.permissions.description')}
                ns="global"
              />
            ) : (
              <Col sm="12" className="d-flex text-truncate align-items-center">
                <div>
                  <JpAvatar
                    className="rounded me-1"
                    imgClassName="cursor-default"
                    color={'light-info'}
                    icon={<FontAwesomeIcon icon={'fa-solid fa-message-text'} />}
                    /* size={'lg'} */
                  />
                </div>

                <div>
                  <JpLabel labelOptions={{ for: 'state' }}>
                    {t('views.permissions.description').slice(0, -1)}
                  </JpLabel>
                  <JpText
                    type="span-table-text"
                    children={getValues('description') || ''}
                  />
                </div>
              </Col>
            )}
          </Col>
          {!view_only && (
            <Col>
              {files.length > 0 && (
                <ListGroup className="mb-1 text-truncate">{fileList}</ListGroup>
              )}
              {!files.length > 0 && (
                <JpFileInput
                  files={files}
                  setFiles={setFiles}
                  fileInvalid={fileInvalid}
                  setFileInvalid={setFileInvalid}
                />
              )}
            </Col>
          )}
          {view_only && (
            <>
              {!!permissionData?.result?.support_media_location && (
                <Col
                  className="d-flex align-items-center"
                  /*  onClick={() =>
                    downloadFile(
                      permissionData?.result?.support_media_location,
                      permissionData?.result?.permission_name
                    )
                  } */
                >
                  <div>
                    <JpAvatar
                      className="rounded me-1"
                      imgClassName="cursor-pointer "
                      color={'light-info'}
                      icon={
                        <FontAwesomeIcon icon={'fa-solid fa-file-arrow-down'} />
                      }
                      /* size={'lg'} */
                    />
                  </div>
                  <div className="col-6 text-truncate cursor-pointer">
                    <JpLabel
                      className="cursor-pointer"
                      labelOptions={{
                        for: 'description'
                      }}
                    >
                      {t(`views.permissions.downloadPermission`)}
                    </JpLabel>
                    <div className="d-flex align-items-center">
                      {/* <span>
                        {permissionData?.result?.permission_name || ''}
                      </span> */}
                      {/*                     <JpLabel
                        labelOptions={{
                          for: 'description'
                        }}
                      >
                        {t('attached')}
                      </JpLabel> */}
                      <JpButton
                        color="link"
                        type="download"
                        className="p-0"
                        id='description'
                        options={{
                          onClick: () => {
                            downloadFile(
                              permissionData?.result?.support_media_location,
                              permissionData?.result?.permission_name
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
      }
      footerContent={
        <>
          {!view_only ? (
            <>
              <JpButton type="cancel" options={{ onClick: onClose }} />
              <JpButton
                type="save"
                loading={editLoading || addLoading}
                options={{ onClick: handleSubmit(onSubmit) }}
              />
            </>
          ) : (
            <JpButton
              color="green"
              type="close"
              options={{ onClick: onClose, iconless: true }}
            />
          )}
        </>
      }
    />
  )
}

export default ModifyPermissionModal
