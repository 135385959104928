// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/horizontal'

import CustomNavbar from '../components/navbar/CustomNavbar'
import { Col, Row } from 'reactstrap'
import logo from '@src/assets/images/logo/logo_white_horizontal.svg'
import { useTranslation } from 'react-i18next'
import WalletAPI from '../services/API'
import { useQuery } from '@tanstack/react-query'
import { user$ } from '@Intelli/utilities'
import { useState } from 'react'
import { useEffect } from 'react'


const CustomFooter = () => {
  const { t } = useTranslation()

  return (
    <Row className="pt-1 pb-1">
      <img
        className="border-end pe-1 border-2"
        style={{ maxWidth: '135px' }}
        src={logo}
        alt="logo"
      />
      <Col>
        <div>
          <small className="footer-text">
            {t('Footer-TextOne')} {new Date().getFullYear()}
            {t('Footer-TextTwo')}
          </small>
        </div>
        <div>
          {/* <small className="footer-text">
            {t('Footer-Text')}
            </small> */}
        </div>
      </Col>
    </Row>
  )
}

const VerticalLayout = props => {
  const API = new WalletAPI()
  const [menuData, setMenuData] = useState([...navigation])
  useEffect(() => {
    if (!!!user$?.value?.company?.contractor) {
      setMenuData([...menuData.filter(i => i.id !== 'times')])
    }
  }, [])

  /*   let filteredMenu = [...navigation]
  if (!!!user$?.value?.company?.contractor) {
    console.log("asd");
    filteredMenu= filteredMenu.filter(i => i.id !== 'times')
  } */
  const {} = useQuery(['profileInfo'], () => API.getUserProfile(), {
    enabled: !!user$?.value?.company?.contractor,
    onSuccess: d => {
      if (!d.time_active) {
        setMenuData([...menuData.filter(i => i.id !== 'times')])
      }
    }
  })
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout
      menuData={menuData}
      footer={<CustomFooter />}
      navbar={props => <CustomNavbar menuData={menuData} {...props} />}
      {...props}
    >
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
