import {
  faHexagonExclamation,
  faSealExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, UncontrolledTooltip } from 'reactstrap'

const ValidatedAccAlert = () => {
  const userInfo = useSelector(state => state.userInfo)

  const { t } = useTranslation()

  if (userInfo?.veriff?.status === 'APPROVED') {
    return null
  }
  return (
    <Alert
      tag={Link}
      className="m-0"
      color="info"
      to={`/auth/legal-representative/${userInfo?.user?.id}`}
      id="veriff"
    >
      <div className="alert-body">
        <FontAwesomeIcon
          icon={faHexagonExclamation}
          className="text-info"
          size="lg"
        />
        {/* <span className="d-none d-md-inline">{t('notVerifiedAcc')}</span> */}
        <UncontrolledTooltip placement="left" target="veriff">
          {t('notVerifiedAcc')}
        </UncontrolledTooltip>
      </div>
    </Alert>
  )
}

export default ValidatedAccAlert
